@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

h1 h2 h3 h4 h5 h6 {
  margin: 0 !important;
  padding: 0 !important;
}
.input-tag {
  @apply w-full p-2 h-11 rounded-md outline-none appearance-none border;
}

.primary {
  @apply bg-primary border border-transparent rounded-md p-3 text-white hover:text-primary hover:bg-white hover:border-primary;
}
.hover {
  @apply bg-white border border-primary p-3 text-primary hover:bg-primary hover:text-white;
}

.PhoneInput input {
  outline: none;
  border: none;
}

.get-code-body {
  height: 100vh - 240px;
}
.toggle {
  --width: 70px;
  --height: calc(var(--width) / 2);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  border: 1px solid #969696;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2px;
  width: calc(var(--height) * 0.6);
  height: calc(var(--height) * 0.6);
  border-radius: 15px;
  border: 1px solid #969696;
  background-color: #dbdbdb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
  border-color: #2196f3;
}

.toggle input:checked + .slider::before {
  border-color: #2196f3;
  background-color: #c6e5ff;
  transform: translateX(calc(var(--width) - 30px));
}

.toggle .labels {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  color: #4d4d4d;
  font-size: 14px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .labels::after {
  opacity: 0;
}

.toggle input:checked ~ .labels::before {
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
