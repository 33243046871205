@import "~antd/dist/antd.css";

 



.TAC-grey {
	background: rgb(187, 187, 187) !important;
	color: white;
	border-color: white;
};

 
@font-face {
	font-family: "GeneralSans";
	src: url("../public/Fonts/WEB/fonts/GeneralSans-Regular.ttf")
	  format("truetype");
}
 
@font-face {
	font-family: "GeneralRegular";
	src: url("../public/Fonts/WEB/fonts//GeneralSans-Regular.eot")
	  format("truetype");
}
 
@font-face {
	font-family: "GeneralSansLighter";
	src: url("../public/Fonts/WEB/fonts/GeneralSans-Light.ttf")
	  format("truetype");
}
@font-face {
	font-family: "Inter";
	src: url("../public/inter-fonts/Inter-Regular.ttf")
	  format("truetype");
}

.general-sans {
	font-family: GeneralSans , 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.general-sans-regular {
	font-family: GeneralRegular , 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.general-sans-light {
	font-family: GeneralSansLighter , 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
 
.inter {
	font-family: Inter , 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.rm-img-border .uploadMain{
	flex: 1
}
.rm-img-border .uploadMain  > div{
	border: none;
	width: 100%;
}

 .inputImgType > .uploadMain  {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0;
	opacity: 0;
 }
 